import type {
  APIClassifyItem,
  APIEffectItem,
  ExampleEffectItem,
  FTImageStoreType,
  UsedEffect
} from "./types";
import {
  CutOutAvatar,
  FTLang,
  cloneCanvas,
  createRecaptcha,
  getObjectTypeString,
  getUploadUrl,
  hanldelFTUserProfile,
  rangNumber,
  recaptchaReady,
  renderRecaptcha,
  sleep,
  uploadS3File,
  utils
} from "src/utils";
import { EnumsShowStatus, errorCode } from "src/constant";
import FTEventTarget, { FTEVENT_CONSTANTS } from "src/utils/FTEventTarget";
import {
  FTGetAiArtImage,
  FTGetLoop,
  getEffectList,
  getExampleEffect,
  loadEffectList
} from "src/server/api";
import {
  actionClickUp,
  actionGenerateResult,
  actionImportImageClick,
  actionImportImageClose,
  actionImportImageResult,
  actionImportImageShow,
  actionSearch,
  actionStartGenerate,
  actionWebError
} from "src/analyze/actions";
import {
  canvasToImage,
  cloneCanvasWithMaxSide,
  cloneCanvasWithSize,
  downloadFile,
  imageLoader
} from "src/utils/canvas";
import { configure, observable } from "mobx";

import FTCanvasStore from "./FTCanvasStore";
import FTUserStore from "./FTUserStore";
import { IEffectItem } from "./types";
import ImageProcessor from "src/ImageProcessor";
import clsx from "clsx";
import { message } from "antd";

/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2021-11-29 20:05:55
 * @Description:
 */

// mobx 配置
configure({
  enforceActions: "never" // 允许直接修改状态
});

message.config({
  top: utils.checkIsMobile() ? 32 : 60
});
function toItem(
  effectItem: APIEffectItem,
  classifyItem: APIClassifyItem
): IEffectItem {
  const isAi = effectItem.special_type === "ai";
  return {
    prodId: effectItem.id,

    classifyId: classifyItem.categoryId,
    classifyKey: classifyItem.categoryId,
    name: effectItem.name,
    enName: effectItem.enName,
    id: effectItem.id,
    type: 2,
    url: effectItem.url,
    isvip: effectItem.isvip,
    isgrey: effectItem.isgray,
    blend: isAi ? 1 : effectItem.blend,
    firstCategory: classifyItem.categoryName,
    firstCategoryEn: classifyItem.categoryEnName,
    secondCategory: effectItem.tagName,
    aigc: isAi,
    isNew: effectItem.subScript.includes("new"),
    isHot: effectItem.subScript.includes("hot"),
    cropFace: effectItem.cropFace,
    detectFaces: effectItem.detectFaces,
    _id: effectItem?._id
  };
}
const FTImageStore = observable.object<FTImageStoreType>({
  originFile: null,
  showRequestErrorModal: false,
  showGuid: false,
  showStatus: EnumsShowStatus.origin,
  loadingGlobalEffect: true,
  timerChooseClass: null,
  initAutoRun: false,
  initImageUrl: "",
  initAutoOpen: false,
  initClassifyId: "",
  initEffectId: "",
  mobileShowCenterAd: true,
  goartOriginImage: null,
  setMobileShowCenterAd: (value: boolean) => {
    FTImageStore.mobileShowCenterAd = value;
  },
  currentImage: null,
  usedEffect: {},
  acturalSize: {
    width: 0,
    height: 0
  },
  currentSize: {
    width: 0,
    height: 0
  },
  resultSize: {
    width: 0,
    height: 0
  },
  prevEffect: null,
  greyImageElement: null,
  effectList: [],
  currentEffect: null,
  defaultEffect: "",
  imageElement: null, // 当前图片元素
  effectElement: null, // 特效生成的图片元素
  effectClassifyMap: [],
  currentClassify: "",
  currentClassifyId: "",
  intensity: {
    max: 100,
    min: 0,
    default: 100
  },
  defaultEffectClassify: "",
  currentIntensity: 50,
  loading: false,

  effectClassify: [
    "Sketch",
    "Cartoon",
    "Pixel",
    "Watercolor",
    "Artist",
    "Art Genre",
    "Universal"
  ],
  choicenessList: [],
  effectTags: [],
  selectEffectTags: [],
  searchText: "",
  searchList: [],
  searchLoading: false,
  imageHD: false,

  currentEffectType: "",
  uploadFaceUrl: null,
  uploadImageUrl: null,
  artUploadImageUrl: null,
  artHDUploadImageUrl: null,
  checkRoot: { load: false, token: null, need: false, id: null },
  examplesList: [],
  progress: {
    upload: 0,
    task: 0,
    download: 0
  },
  get totalProgress() {
    return (
      this.progress.upload * 0.5 +
      this.progress.task * 0.4 +
      this.progress.download * 0.1
    );
  },
  getExampleEffectList() {
    getExampleEffect().then((res) => {
      const effectList = this.effectList;
      this.examplesList = res.data.map((item) => {
        const effectItem = effectList.find((ele) => {
          const id = ele.prodId || ele.id;
          return id + "" === item.templateId + "";
        });
        return {
          ...item,
          name: effectItem?.name ?? "",
          langKey: effectItem?.langKey ?? ""
        };
      });
    });
  },
  changeCurrentIntensity(value: number) {
    this.currentIntensity = value;
    FTCanvasStore.draw();
  },
  handelError(key) {
    this.showStatus = EnumsShowStatus.origin;
    this.loading = false;
    this.currentEffect = this.prevEffect;
    message.error(FTLang._(key || "goart_effect_use_error"));
  },
  LoopExecute(id: string, effect: any, nums: number = 0, position?: string) {
    //0排队 1成功 2失败 3超时 /4生成中
    const statusType = {
      connectionTimeout: 3,
      pending: 0,
      fail: 2,
      making: 4,
      success: 1
    };
    this.progress.task = rangNumber(10, 90, this.progress.task + 5);
    FTGetLoop(id)
      .then((res: any) => {
        if (nums >= 50) {
          message.error({
            duration: 4,
            content: FTLang._("network_error_content"),
            className: clsx(
              "messageZindex",
              FTCanvasStore.isMobile && "mobileMessageClass"
            )
          });
          this.showStatus = EnumsShowStatus.origin;
          this.loading = false;
          return;
        }
        if (res.code === "000") {
          if (
            res.data.status === statusType.pending ||
            res.data.status === statusType.making
          ) {
            setTimeout(() => {
              this.LoopExecute(res.data.taskId, effect, nums + 1);
            }, 2000);
          } else {
            this.progress.task = 100;
            if (res.data.status === statusType.success) {
              this.progress.download = 10;
              actionGenerateResult(position || "", true, "");
              downloadFile(res.data.resultImg, (progress) => {
                this.progress.download = progress * 0.85;
              })
                .then(() => {
                  return imageLoader(res.data.resultImg);
                })
                .then((val) => {
                  this.progress.download = 100;
                  this.setUsedEffect({
                    effect,
                    target: val,
                    hasDraw: true
                  });

                  if (res.data.whetherViolations) {
                    actionGenerateResult(
                      position || "",
                      false,
                      "goart_effect_image_violation_rule"
                    );
                    this.handelError("goart_effect_image_violation_rule");
                  }
                  this.showStatus = EnumsShowStatus.result;
                  this.loading = false;
                })
                .catch((error) => {
                  this.loading = false;
                  this.showStatus = EnumsShowStatus.origin;
                  actionGenerateResult(position || "", false, error);
                  this.handelError();
                });
            } else {
              if (
                res.data?.failReason?.includes("No face detected in the image")
              ) {
                actionGenerateResult(
                  position || "",
                  false,
                  "goart_aigc_face_check_error_toast"
                );
                this.handelError("goart_aigc_face_check_error_toast");
              } else {
                actionGenerateResult(position || "", false, "");
                this.handelError();
              }
            }
          }
        }
      })
      .catch((error) => {
        actionGenerateResult(position || "", false, error);
        this.handelError();
      });
  },
  async getAigcEffect(
    effect: any,
    tokens = "",
    checkRobot = false,
    position?: string
  ) {
    if (!FTImageStore.currentImage) {
      FTImageStore.uploadImage("click_effect_upload");
      return;
    }
    this.loading = true;
    let token = tokens;
    this.currentEffect = effect;
    this.progress.download = 0;
    this.progress.upload = 0;
    this.progress.task = 0;
    actionStartGenerate(position || "");
    // 获取上传链接
    try {
      if (effect.detectFaces) {
        if (!this.uploadFaceUrl) {
          const instance = new CutOutAvatar();
          const face = await instance.startProcess(this.currentImage as File);
          if (face?.result) {
            const file = face.file;
            if (effect.cropFace) {
              try {
                const key = await this.uploadFile(file, (progress) => {
                  this.progress.upload = progress;
                });
                this.uploadFaceUrl = key;
              } catch (error) {
                this.showRequestErrorModal = true;
                this.loading = false;
                return;
              }
            } else {
              // 如果只需要人脸检测
              let result: File = await new Promise((resolve) => {
                const newImage = cloneCanvasWithMaxSide(
                  this.imageElement as HTMLCanvasElement,
                  1024
                );
                newImage.toBlob((blob: Blob | null) => {
                  let files = new File([blob as Blob], this.currentImage.name);
                  resolve(files);
                }, "image/jpeg");
              });
              try {
                const key = await this.uploadFile(result, (progress) => {
                  this.progress.upload = progress;
                });
                this.uploadImageUrl = key;
              } catch (error) {
                this.showRequestErrorModal = true;
                this.loading = false;
                return;
              }
            }
          } else {
            this.loading = false;
            this.showStatus = EnumsShowStatus.origin;
            message.error({
              content: FTLang._("goart_aigc_face_check_error_toast"),
              className: clsx(
                "messageZindex",
                FTCanvasStore.isMobile && "mobileMessageClass"
              )
            });
            actionGenerateResult(position || "", false, face?.message ?? "");
            return;
          }
        }
      } else if (!this.uploadImageUrl) {
        let result: File = await new Promise((resolve) => {
          const newImage = cloneCanvasWithMaxSide(
            this.imageElement as HTMLCanvasElement,
            1024
          );
          newImage.toBlob((blob: Blob | null) => {
            let files = new File([blob as Blob], this.currentImage.name);
            resolve(files);
          }, "image/jpeg");
        });
        try {
          const key = await this.uploadFile(result, (progress) => {
            this.progress.upload = progress;
          });
          this.uploadImageUrl = key;
        } catch (error) {
          this.showRequestErrorModal = true;
          this.loading = false;
          return;
        }
      }
      this.progress.upload = 100;
      if (!this.checkRoot.load) {
        await createRecaptcha();
        this.checkRoot.load = true;
      }
      if (!checkRobot) {
        token = await recaptchaReady("aigc_check");
      }
      this.progress.task = 0;
      this.setMobileShowCenterAd(true);
      let result = await FTGetAiArtImage({
        img: effect.cropFace
          ? (this.uploadFaceUrl as string)
          : (this.uploadImageUrl as string),
        templateId: effect.prodId,
        // process.env.REACT_APP_ENVIRONMENT === 'production'
        // ? effect.prodId
        // : effect.id,
        googleToken: token,
        googleAction: checkRobot ? "" : "aigc_check"
      });
      //处理code
      this.progress.task = 5;
      if (result.code === "000") {
        this.LoopExecute(result.data.taskId, effect, 0, position || "");
      } else if (result.code === "831") {
        actionGenerateResult(position || "", false, "831");
        this.checkRoot = { ...this.checkRoot, need: true };
        this.loading = false;
        this.showStatus = EnumsShowStatus.origin;
        if (this.checkRoot.id !== null) {
          (window as any).grecaptcha.enterprise.reset(this.checkRoot.id);
        } else {
          this.rootExeCute(position);
        }
      } else if (result.code === "504") {
        this.loading = false;
        this.showStatus = EnumsShowStatus.origin;
        message.error({
          content: FTLang._(FTUserStore.get504ErrorMsg()),
          className: clsx(
            "messageZindex",
            FTCanvasStore.isMobile && "mobileMessageClass"
          )
        });
        actionGenerateResult(position || "", false, "504");
        if (!FTUserStore.userInfo) {
          FTUserStore.showLogin({
            isSignIn: false,
            options: {
              position: "download_fullsize"
            },
            isShowScenses: true,
            otherParam: {
              scenesId: "014",
              width: FTImageStore.currentSize.width,
              height: FTImageStore.currentSize.height,
              imgSrc: FTCanvasStore.canvas!.toDataURL("image/jpeg")
            },
            callback: () => {
              this.getAigcEffect(effect, token, checkRobot, position);
            },
            errorCallBack: () => {
              this.currentEffect = this.prevEffect;
            }
          });
          return;
        }
        if (FTUserStore.isSvip) {
          this.currentEffect = this.prevEffect;
        } else {
          FTUserStore.showPaymnet({
            vipType: "vip",
            position: "download_hd",
            otherParam: {
              scenesId: "012"
            },
            callback: () => {
              this.getAigcEffect(effect, token, checkRobot, position);
            },
            errorCallBack: () => {
              this.currentEffect = this.prevEffect;
            }
          });
        }
      } else {
        actionGenerateResult(position || "", false, "");
        this.handelError(undefined, position || "");
      }
    } catch (error) {
      if (error === errorCode.robotFail) {
        actionWebError({
          project_type: "goart",
          result_type: "robot_verification",
          fail_reason: "robot_load_is_failed"
        });
      }
      const message =
        error instanceof Error ? error.message : (error as string);
      actionGenerateResult(position || "", false, message);
      this.handelError(undefined, position || "");
    }
  },
  rootExeCute(position?: string) {
    this.checkRoot.id = renderRecaptcha(
      "goart_content-root_fra",
      (token: string) => {
        this.checkRoot = { ...this.checkRoot, need: false };
        this.getAigcEffect(this.currentEffect, token, true, position);
      }
    );
  },
  async uploadFile(file, fn: Function) {
    fn?.(0);
    const uploadUrls = await getUploadUrl();
    fn?.(10);
    let { uploadUrl, key, backupUploadUrl } = uploadUrls;
    await uploadS3File(uploadUrl, file!, (progress) => {
      fn?.(90 * progress);
    }).catch(async () => {
      fn?.(10);
      await uploadS3File(backupUploadUrl, file!, (progress) => {
        fn?.(90 * progress);
      });
    });
    return key;
  },
  async getGoartEffect(effect: any, position?: string) {
    if (!FTImageStore.currentImage) {
      return FTImageStore.uploadImage("click_effect_upload");
    }
    this.prevEffect = this.currentEffect;
    this.progress.download = 0;
    this.progress.upload = 0;
    this.progress.task = 0;

    if (effect?.aigc) {
      if (this.usedEffect[effect.id]) {
        this.currentEffect = effect;
        this.effectElement = this.usedEffect[effect.id];
        this.resultSize = {
          width: this.effectElement.naturalWidth,
          height: this.effectElement.naturalHeight
        };
        this.currentIntensity = effect.blend * 100;
        FTCanvasStore.draw();
        this.loading = false;
        this.showStatus = EnumsShowStatus.result;
        return;
      }

      this.getAigcEffect(effect, "", false, position);
      return;
    }
    if (effect.name === "Original") {
      this.currentEffect = effect;
      this.effectElement = null;
      FTCanvasStore.draw();
    } else {
      this.loading = true;
      this.currentEffect = effect;
      if (this.usedEffect[effect.id]) {
        this.effectElement = this.usedEffect[effect.id];
        this.resultSize = {
          width: this.effectElement.naturalWidth,
          height: this.effectElement.naturalHeight
        };
        this.currentIntensity = effect.blend * 100;
        FTCanvasStore.draw();
        this.loading = false;
        this.showStatus = EnumsShowStatus.result;
      } else {
        actionStartGenerate(position || "");
        if (!this.artUploadImageUrl) {
          let result: File = await new Promise((resolve) => {
            const newImage = cloneCanvasWithMaxSide(
              this.imageElement as HTMLCanvasElement,
              720
            );
            newImage.toBlob((blob: Blob | null) => {
              let files = new File([blob as Blob], this.currentImage.name);
              resolve(files);
            }, "image/jpeg");
          });
          try {
            const key = await this.uploadFile(result, (progress) => {
              this.progress.upload = progress;
            });
            this.artUploadImageUrl = key;
          } catch (error) {
            this.showRequestErrorModal = true;
            this.loading = false;
            return;
          }
        }
        this.progress.upload = 100;
        this.progress.task = 5;
        this.progress.download = 0;
        const interval = setInterval(() => {
          this.progress.task = rangNumber(10, 90, this.progress.task + 5);
        }, 1000);

        this.setMobileShowCenterAd(true);
        ImageProcessor.processByUrl(this.artUploadImageUrl!, effect.id)
          .then(async (res) => {
            clearInterval(interval);
            this.progress.task = 100;
            this.progress.download = 100;
            const isVertical = this.acturalSize.height > this.acturalSize.width;

            const ratio = this.acturalSize.width / this.acturalSize.height;
            const newWidth = isVertical
              ? this.acturalSize.height * ratio
              : this.acturalSize.width;
            const newHeight = isVertical
              ? this.acturalSize.height
              : this.acturalSize.width / ratio;
            this.goartOriginImage = cloneCanvasWithSize(
              res,
              res.width,
              res.height
            );
            const newCanvas = cloneCanvasWithSize(res, newWidth, newHeight); // goart 特效会生成1：1的图片 这里需要根据原图比例生成 新的图片
            const image = canvasToImage(newCanvas);
            if (effect.isgrey) {
              this.greyImageElement = await ImageProcessor.PreProcessGray(
                this.imageElement!
              );
            }
            actionGenerateResult(position || "", true, "");
            // goart 特效会压缩，所以使用原图的比例;

            this.setUsedEffect({
              effect,
              target: image
            });
            await sleep(500);
            this.showStatus = EnumsShowStatus.result;
            this.loading = false;
          })
          .catch((err) => {
            clearInterval(interval);
            this.showStatus = EnumsShowStatus.origin;
            actionGenerateResult(position || "", false, err);
            this.handelError();
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  },
  setCurrentImage(target: any) {
    this.currentImage = target;
    this.reset();
  },

  setEffectList(list: IEffectItem[]) {
    //风格列表
    this.effectList = list.map((item: IEffectItem) => {
      const langId = (item.id as string).length > 5 ? item.prodId : item.id; //多语言文档使用的是prodId作为主键
      item.url = `${item.url}?v=${process.env.REACT_APP_VERSION}`;
      const tag_effect_id = `tag_effect_${langId}`;
      const effect_lang_key = `effect_lang_${langId}`;
      item.langKey = effect_lang_key;
      item.tagKey = tag_effect_id;
      item.secondCategory = FTLang.getSpecialLang(tag_effect_id, "en_US");
      return item;
    });
    const effectIdMap: Record<string, any> = {};
    this.effectList.forEach((item: any) => {
      const langId = item.id.length > 5 ? item.prodId : item.id; //多语言文档使用的是prodId作为主键
      effectIdMap[langId] = item;
    });
    this.choicenessList = JSON.parse(
      JSON.stringify(this.effectList.filter((item: any) => item.choiceness))
    );
    // if( this.effectClassifyMap.length === 0){
    //   this.effectClassifyMap = this.effectClassify.map((item: any, index) => {
    //     return {
    //       title: item,
    //       key:,
    //       id,
    //       isNew
    //       list: [],
    //       collapse: false || this.defaultEffectClassify === item,
    //     };
    //   });
    //   for (let i = 0; i < this.effectList.length; i++) {
    //     let effect = this.effectList[i];
    //     let firstCategory = effect.firstCategory;
    //     for (let j = 0; j < this.effectClassifyMap.length; j++) {
    //       if (this.effectClassifyMap[j].title === firstCategory) {
    //         this.effectClassifyMap[j].list.push(effect);
    //         if (this.defaultEffect === effect.id) {
    //           this.defaultEffectClassify = firstCategory;
    //           this.effectClassifyMap[j].collapse = true;
    //         }
    //       }
    //     }
    //   }
    // }else
    //  {

    this.effectClassifyMap.forEach((classifyItem) => {
      if (classifyItem.effectIds) {
        classifyItem.list = classifyItem.effectIds
          .map((id: string) => {
            return effectIdMap[id];
          })
          .filter((item: any) => item);
        classifyItem.isNew = classifyItem.list.some((item: any) => item.isNew);
        classifyItem.list.forEach((effectItem) => {
          effectItem.firstCategory = classifyItem.title;
          effectItem.classifyKey = classifyItem.key;
          effectItem.classifyId = classifyItem.id;
        });
      } else {
        classifyItem.list = [];
        classifyItem.isNew = false;
      }
      classifyItem.lang = FTLang._(classifyItem.key);
      classifyItem.collapse = false;
    });
    if (this.defaultEffect) {
      const item = this.effectList.find(
        (item: any) => item.id === this.defaultEffect
      );
      if (item) {
        this.defaultEffectClassify = item.firstCategory;
      }
    }
    // }

    // 只針對第一次進來的用戶才會默認使用推介第一個特效
    // if(!this.defaultEffect) {
    //   let firstEntry = FTGetLocalData("fotor_goart_first_entry")
    //   if(!firstEntry) {
    //     this.defaultEffect = this.choicenessList[0]
    //     FTSaveLocalData("fotor_goart_first_entry", true)
    //   }
    // }
  },

  setCurrentImageElement(target: HTMLCanvasElement, currentSize, acturalSize) {
    this.imageElement = target;
    this.currentSize = currentSize;
    this.acturalSize = acturalSize;
    this.effectElement = null;
    if (!FTCanvasStore.compareCanvas) {
      FTCanvasStore.compareCanvas = cloneCanvas(target);
      (FTCanvasStore.compareCanvas as HTMLCanvasElement).classList.add(
        "compare-canvas"
      );
      FTCanvasStore.compareCanvas.style.display = "none";
    }
    FTCanvasStore.draw();
  },

  setUsedEffect(data: UsedEffect) {
    this.resultSize = {
      width: data.target.naturalWidth,
      height: data.target.naturalHeight
    };
    this.effectElement = data.target;
    this.currentIntensity = data.effect.blend * 100;
    this.usedEffect[data.effect.id] = data.target;
    window.dispatchEvent(new Event("resize"));
    FTCanvasStore.draw(data?.hasDraw);
  },
  checkUsedEffect(effect: string) {
    return !!this.usedEffect[effect];
  },
  reset() {
    this.usedEffect = {};
    this.effectElement = null;
  },
  getEffectIndex() {
    return this.effectList.indexOf(this.currentEffect);
  },
  hasPrev() {
    if (!this.currentEffect) return false;
    if (this.currentEffect.name === "Original") {
      return false;
    }
    let index = this.getEffectIndex();
    return index > 0;
  },
  hasNext() {
    if (!this.currentEffect) return false;
    if (this.currentEffect.name === "Original") return true;
    let index = this.getEffectIndex();
    return index < this.effectList.length - 1;
  },
  next() {
    if (this.hasNext() && !this.loading) {
      let index = this.getEffectIndex();
      let newEffect = this.effectList[++index];
      this.getGoartEffect(newEffect);
    }
  },
  prev() {
    if (this.hasPrev() && !this.loading) {
      let index = this.getEffectIndex();
      let newEffect = this.effectList[--index];
      this.getGoartEffect(newEffect);
    }
  },
  getEffectTags() {
    return [
      "goart_effect_tag_Monochrome",
      "goart_effect_tag_Colorful",
      "goart_effect_tag_Bright",
      "goart_effect_tag_Dark",
      "goart_effect_tag_Saturated",
      "goart_effect_tag_Faded",
      "goart_effect_tag_Blue",
      "goart_effect_tag_Red",
      "goart_effect_tag_Green",
      "goart_effect_tag_Yellow",
      // "goart_effect_tag_Gray",
      "goart_effect_tag_White",
      "goart_effect_tag_Pink",
      "goart_effect_tag_Orange",
      "goart_effect_tag_Lines",
      "goart_effect_tag_Dotted",
      "goart_effect_tag_Blocks",
      "goart_effect_tag_Textured",
      "goart_effect_tag_Retro"
      // "goart_effect_tag_Pop",
    ];
  },

  getEffectList(language = "en_US") {
    // loadEffectList({
    //   url: `/json/effect/effect.json?v=${process.env.REACT_APP_VERSION}`
    // }).then((res) => {
    //   const list = res.list;
    //   res.list = list.map((item: any) => {
    //     const langId = item.id.length > 5 ? item.prodId : item.id; //多语言文档使用的是prodId作为主键
    //     if (item?.aigc) {
    //       return {
    //         ...item,

    //         id:
    //           process.env.REACT_APP_ENVIRONMENT === "production"
    //             ? item.prodId
    //             : item.id
    //       };
    //     }
    //     return { ...item };
    //   });

    //   const exe = () => {
    //     let search = window.location.search.slice(1);
    //     let searchParms: any = {};
    //     search.split("&").map((item) => {
    //       let i = item.split("=");
    //       searchParms[i[0]] = i[1];
    //       return item;
    //     });
    //     // 将 特效id 转成 number 类型
    //     this.defaultEffect = searchParms["effect"];
    //     if (searchParms["classify"]) {
    //       let obj = this.effectClassify.find((items) => {
    //         return (
    //           items?.toLocaleLowerCase() ===
    //           searchParms["classify"]?.toLocaleLowerCase()
    //         );
    //       });
    //       if (obj) {
    //         FTImageStore.defaultEffectClassify = obj;
    //         FTImageStore.currentEffectType = obj;
    //       }
    //     }
    //   };
    //   exe();
    //   this.effectClassifyMap = res.classifyList;
    //   this.effectClassify =
    //     res.classifyList.map((item) => item.title) ||
    //     res.classify ||
    //     this.effectClassify;
    //   this.effectTags = res.tags || this.effectTags;
    //   this.setEffectList(res.list);
    //   const handleFireSrcoll = (top) => {
    //     /** 选中style之后要滚动到对应位置，需要切换之后才能找到对应的元素，这里使用事件的方式好控制 */
    //     setTimeout(() => {
    //       FTEventTarget.getInstance().fireEvent({
    //         type: FTEVENT_CONSTANTS.SCROLL_TO_VIEW,
    //         data: { ...this.currentEffect, top: top }
    //       });
    //     }, 600);
    //   };
    //   if (this.initEffectId) {
    //     let effect = this.effectList.find((item) => {
    //       const id = item.prodId || item.id;
    //       return id + "" === this.initEffectId;
    //     });
    //     if (effect) {
    //       this.currentEffect = effect;
    //       /** 选中style之后要滚动到对应位置，需要切换之后才能找到对应的元素，这里使用事件的方式好控制 */
    //       handleFireSrcoll(200);
    //     }
    //   }
    //   if (this.currentClassify) {
    //     const effectClassify = this.effectClassifyMap.find((item) => {
    //       let searchText = this.currentClassify.toLocaleLowerCase();
    //       if (searchText.indexOf("_") >= 0) {
    //         searchText = searchText.split("_").join(" ");
    //       }
    //       return item.title.toLocaleLowerCase() === searchText;
    //     });
    //     if (effectClassify) {
    //       this.currentClassify = "";
    //       this.chooseSelectClassify(effectClassify.title);
    //       /** 选中style之后要滚动到对应位置，需要切换之后才能找到对应的元素，这里使用事件的方式好控制 */
    //       handleFireSrcoll(0);
    //       this.currentEffectType = effectClassify.title;
    //     } else {
    //       this.currentClassify = "";
    //     }
    //   }
    //   if (this.initClassifyId) {
    //     const effectClassify = this.effectClassifyMap.find(
    //       (item) => item.id + "" === this.initClassifyId
    //     );
    //     if (effectClassify) {
    //       this.currentClassify = "";
    //       this.chooseSelectClassify(effectClassify.title);
    //       this.currentEffectType = effectClassify.title;
    //     }
    //   }
    //   if (this.initImageUrl) {
    //     this.startAUrl(this.initImageUrl);
    //   }
    // });
    // this.loadingGlobalEffect = true;
    return getEffectList(language)
      .then((res) => {
        const tags: string[] = [];
        res.data.forEach((classifyItem) => {
          tags.push(
            ...classifyItem.effectList.flatMap((effectItem) =>
              effectItem.tagName.split(",")
            )
          );
        });
        this.effectClassify = res.data.map(
          (classifyItem) => classifyItem.categoryName
        );
        this.effectTags = this.getEffectTags().map((item) => FTLang._(item));
        this.effectClassifyMap = res.data.map((classifyItem) => {
          const id = classifyItem.categoryId;
          return {
            title: classifyItem.categoryName,
            enName: classifyItem.categoryEnName,
            key: id,
            id: id,
            needShowNew: true,
            isNew: classifyItem.effectList.some((effect) =>
              effect.subScript.includes("new")
            ),
            isAvatar:
              id === "a4ca1dc1-9269-46e4-95ec-f2910db83db1" || id === "4",
            effectIds: classifyItem.effectList.map(
              (effectItem) => effectItem.id
            ),
            list: classifyItem.effectList.map((effectItem) =>
              toItem(effectItem, classifyItem)
            ),
            lang: classifyItem.categoryName,
            collapse: false
          };
        });
        const needHideNewsIds = ["10"];
        this.effectClassifyMap.forEach((classItem) => {
          if (needHideNewsIds.includes(classItem.id)) {
            classItem.needShowNew = false;
            // classItem.list.forEach(item=>{
            //   item.isNew = false;
            // })
          }
        });
        this.effectClassifyMap = [...this.effectClassifyMap];
        this.effectList = this.effectClassifyMap.flatMap(
          (classify) => classify.list
        );
        if (this.examplesList.length > 0) {
          this.examplesList.forEach((item) => {
            const effectItem = this.effectList.find((ele) => {
              const id = ele.prodId || ele.id;
              return id + "" === item.templateId + "";
            });
            item.name = effectItem?.name ?? "";
            item.langKey = effectItem?.langKey ?? "";
          });
        }
        const handleFireSrcoll = (top) => {
          if (!this.loadingGlobalEffect) {
            return;
          }
          /** 选中style之后要滚动到对应位置，需要切换之后才能找到对应的元素，这里使用事件的方式好控制 */
          setTimeout(() => {
            FTEventTarget.getInstance().fireEvent({
              type: FTEVENT_CONSTANTS.SCROLL_TO_VIEW,
              data: { ...this.currentEffect, top: top }
            });
          }, 600);
        };
        if (this.defaultEffect) {
          const item = this.effectList.find(
            (item: any) => item.id === this.defaultEffect
          );
          if (item) {
            this.defaultEffectClassify = item.firstCategory;
          }
        }
        if (this.initEffectId) {
          let effect = this.effectList.find((item) => {
            const id = item.prodId || item.id;
            return id + "" === this.initEffectId;
          });
          if (effect) {
            this.currentEffect = effect;
            /** 选中style之后要滚动到对应位置，需要切换之后才能找到对应的元素，这里使用事件的方式好控制 */
            handleFireSrcoll(200);
          }
        }
        if (this.currentClassify) {
          const effectClassify = this.effectClassifyMap.find((item) => {
            let searchText = this.currentClassify.toLocaleLowerCase();
            if (searchText.indexOf("_") >= 0) {
              searchText = searchText.split("_").join(" ");
            }
            return item.title.toLocaleLowerCase() === searchText;
          });
          if (effectClassify) {
            this.currentClassify = "";
            this.chooseSelectClassify(effectClassify.id, true);
            /** 选中style之后要滚动到对应位置，需要切换之后才能找到对应的元素，这里使用事件的方式好控制 */
            handleFireSrcoll(0);
            this.currentEffectType = effectClassify.title;
            this.currentClassifyId = effectClassify.id;
          } else {
            this.currentClassify = "";
          }
        }
        if (this.currentClassifyId) {
          const effectClassify = this.effectClassifyMap.find((item) => {
            return item.id + "" === this.currentClassifyId;
          });
          if (effectClassify) {
            this.currentClassify = "";
            this.chooseSelectClassify(effectClassify.id, true);
            /** 选中style之后要滚动到对应位置，需要切换之后才能找到对应的元素，这里使用事件的方式好控制 */
            handleFireSrcoll(0);
            this.currentEffectType = effectClassify.title;
            this.currentClassifyId = effectClassify.id;
          } else {
            this.currentClassify = "";
          }
        }
        if (this.initClassifyId) {
          const effectClassify = this.effectClassifyMap.find(
            (item) => item.id + "" === this.initClassifyId
          );
          if (effectClassify) {
            this.currentClassify = "";
            this.chooseSelectClassify(effectClassify.id, true);
            this.currentEffectType = effectClassify.title;
            this.currentClassifyId = effectClassify.id;
          }
        }
        if (this.initImageUrl && this.initEffectId) {
          this.startAUrl(this.initImageUrl, true);
        } else if (this.initClassifyId && this.initImageUrl) {
          this.showGuid = true;
          this.startAUrl(this.initImageUrl, false);
        } else if (this.initClassifyId) {
          this.showGuid = true;
        } else if (this.initImageUrl) {
          this.startAUrl(this.initImageUrl, false);
        }

        if (this.searchText.length > 0) {
          this.search();
        }

        this.initEffectId = "";
        this.initAutoRun = false;
        this.initImageUrl = "";
        this.initClassifyId = "";
      })
      .finally(() => {
        this.loadingGlobalEffect = false;
      });
  },
  async uploadImage(
    position: string,
    file?: File | null,
    checkRobot = false,
    useFocuse = false,
    autorun = true
  ) {
    let img: Array<File>;
    actionImportImageClick(position);
    if (file) {
      img = [file as File];
    } else {
      actionImportImageShow(position);
      img = await utils.selectImageFile({
        multiple: false,
        useFocus: useFocuse
      });
      actionImportImageClose(position);
    }
    actionImportImageClose(position);
    if (img && img.length > 0) {
      actionImportImageResult(position, true);
      hanldelFTUserProfile();
      FTCanvasStore.changeLoading(true);
      this.uploadImageUrl = null;
      this.artUploadImageUrl = null;
      this.artHDUploadImageUrl = null;
      const checkResult = await utils.checkImage(img[0]);
      if (!checkResult.result) {
        message.error(FTLang._(checkResult.message));
        FTCanvasStore.changeLoading(false);
        actionGenerateResult(position, false, checkResult.message);
        return;
      }
      const [first] = img;
      this.originFile = first;
      let {
        canvas: imageElement,
        acturalSize,
        currentSize
      } = await utils.getImageFromFile(img[0]);
      this.setCurrentImageElement(imageElement, currentSize, acturalSize);
      this.setCurrentImage(img[0]);
      window.dispatchEvent(new Event("resize"));
      FTCanvasStore.changeLoading(false);
      if (!autorun) {
        return;
      }
      if (this.currentEffect && this.currentEffect !== "original") {
        actionClickUp(
          utils.toFixed(this.currentEffect.id, 0),
          this.currentEffect.enName,
          {
            button_position: "goart_effect"
          }
        );
        this.getGoartEffect(this.currentEffect);
      } else {
        //有默认风格
        let effect: IEffectItem | null = null;
        const defaultEffectTypeString = getObjectTypeString(this.defaultEffect);
        if (defaultEffectTypeString === "Object") {
          effect = this.defaultEffect;
        } else if (
          ["Number", "String"].includes(defaultEffectTypeString) &&
          this.defaultEffect
        ) {
          this.effectClassifyMap = this.effectClassifyMap.map((item: any) => {
            if (
              item.list.some((ite: any) => {
                if (ite.id === this.defaultEffect) {
                  effect = ite;
                  return true;
                }
                return false;
              })
            ) {
              item.collapse = true;
            }
            return item;
          });
        }
        if (effect) {
          actionClickUp(
            utils.toFixed(parseInt(effect.id as string), 0),
            effect.enName,
            {
              button_position: "goart_effect"
            }
          );
          this.getGoartEffect(effect);
        }
      }
    } else {
      if (this.currentEffect) {
        this.currentEffect = null;
      }
      actionImportImageResult(position, false);
    }
  },
  chooseSelectClassify(classifyId: string, force = false) {
    if (this.currentClassifyId === classifyId && !force) {
      return;
    }

    this.currentClassifyId = classifyId;
    const item = this.effectClassifyMap.find((item) => item.id === classifyId);
    // if (list) {
    //   list.collapse = !list.collapse;
    // }
    this.searchLoading = true;
    this.searchList = [];
    this.selectEffectTags = [];
    /* 点击分类搜索的不输入 */
    // this.searchText = classify.trim();
    this.searchText = "";
    if (item) {
      this.currentClassify = item.enName;
      if (this.timerChooseClass) {
        clearTimeout(this.timerChooseClass);
        this.timerChooseClass = null;
      }
      if (force) {
        this.searchList = [...item.list];
        this.searchLoading = false;
        this.timerChooseClass = null;
      } else {
        this.timerChooseClass = setTimeout(() => {
          this.searchList = [...item.list];
          this.searchLoading = false;
          this.timerChooseClass = null;
        }, 500);
      }

      // sleep(500,(timer)=>{
      //   this.timerChooseClass = timer;
      // }).then(() => {

      //   this.searchList = [...item.list];
      //   this.searchLoading = false;
      //   this.timerChooseClass = null;
      // });
    } else {
      this.currentClassify = "";
      this.currentClassifyId = "";
    }
  },

  addSelectTags(tag) {
    this.currentClassify = "";
    this.currentClassifyId = "";
    let index = this.selectEffectTags.indexOf(tag);
    if (index === -1) {
      // this.selectEffectTags = [...this.selectEffectTags, tag];
      this.selectEffectTags = [tag];
    } else {
      this.selectEffectTags.splice(index, 1);
      this.selectEffectTags = [...this.selectEffectTags];
    }
    this.searchText = this.selectEffectTags.map((item) => `${item}`).join(" ");
    if (this.selectEffectTags.length) {
      // 执行搜索操作
      this.search();
    } else {
      // 显示分类
      this.searchList = [];
    }
  },
  clearSelectTags() {
    this.selectEffectTags = [];
  },
  search() {
    this.searchLoading = true;
    // 支持使用 数字 空格和逗号（英文）进行搜索
    // let searchText = this.searchText
    //   .replace(/[^\w| |,]/g, () => "")
    //   .split(/[ |,]/)
    //   .filter((item) => item)
    //   .map((item) => item.toLocaleLowerCase());
    // let searchText = this.searchText
    //   .toLocaleLowerCase()
    //   .trim()
    //   .split(/[ |,]/g)
    //   .map((item) => item.trim())
    //   .filter((item) => item);
    let searchText = [this.searchText.trim().toLocaleLowerCase()].filter(
      (item) => item
    );
    let searchList = new Set();
    let precisionList = new Set();
    // if (this.selectEffectTags.length) {
    //   searchText = this.selectEffectTags
    //     .slice()
    //     .map((item) => item && item.toLocaleLowerCase());
    //   for (let j = 0; j < this.effectList.length; j++) {
    //     let effect = this.effectList[j];
    //     const secondCategoryStr =
    //       effect.secondCategory || FTLang._(effect.tagKey || "") || "";
    //     let secondCategory =
    //       secondCategoryStr
    //         ?.toLocaleLowerCase()
    //         .split(/[ |,]/g)
    //         .filter((item: any) => item) ?? [];
    //     if (
    //       searchText.every((item: any) =>
    //         secondCategory.some((ite: any) => item === ite)
    //       )
    //     ) {
    //       searchList.add(effect);
    //     }
    //     const secondCategoryStr2 = effect.secondCategory || "";
    //     let secondCategory2 =
    //       secondCategoryStr2
    //         ?.toLocaleLowerCase()
    //         .split(/[ |,]/g)
    //         .filter((item: any) => item)
    //         .map((item) => FTLang._(`goart_effect_tag_${item}`)) ?? [];
    //     if (
    //       searchText.every((item: any) =>
    //         secondCategory2.some((ite: any) => item === ite)
    //       )
    //     ) {
    //       searchList.add(effect);
    //     }
    //   }
    // } else {

    for (let i = 0; i < searchText.length; i++) {
      let text = searchText[i];
      for (let j = 0; j < this.effectList.length; j++) {
        let effect = this.effectList[j];
        const localName = effect.name.toLocaleLowerCase();
        if (localName === text) {
          precisionList.add(effect);
        } else if (localName.startsWith(text)) {
          searchList.add(effect);
        }
        if (effect.firstCategory.toLocaleLowerCase().startsWith(text)) {
          searchList.add(effect);
        }
        if (
          effect.secondCategory
            ?.toLocaleLowerCase()
            .split(/[ |,]/g)
            .filter((item: any) => item)
            .some((item: any) => item.startsWith(text)) ??
          false
        ) {
          searchList.add(effect);
        }

        // if (effect.langKey) {
        //   const lang = FTLang._(effect.langKey);
        //   if (lang && lang.toLocaleLowerCase().indexOf(text) > -1) {
        //     searchList.add(effect);
        //   }
        // }
        // if (effect.classifyKey) {
        //   const lang = FTLang._(effect.classifyKey);
        //   if (lang && lang.toLocaleLowerCase().indexOf(text) > -1) {
        //     searchList.add(effect);
        //   }
        // }
        // if (effect.tagKey) {
        //   const lang = FTLang._(effect.tagKey);
        //   if (lang && lang.toLocaleLowerCase().indexOf(text) > -1) {
        //     searchList.add(effect);
        //   }
        // }
      }
    }
    // }
    // 如果使用 array.from, 会使数据失去连通性
    for (let item of searchList.values()) {
      precisionList.add(item);
    }
    this.searchList = [];
    for (let item of precisionList.values()) {
      this.searchList.push(item);
    }

    // 删除重复的数据
    this.searchList = utils.unique(this.searchList, "id");

    this.searchLoading = false;

    // const computedSearchNumber = (length: number) => {
    //   if(length === 0) {
    //     return "0"
    //   } else if(length > 0 && length <= 10) {
    //     return "1-10"
    //   } else if(length > 11 && length <= 20) {
    //     return "11-20"
    //   } else if(length > 21 && length <= 30) {
    //     return "21-30"
    //   } else if(length > 31 && length <= 40) {
    //     return "31-40"
    //   } else {
    //     return "41以上"
    //   }
    // }
    if (this.searchText) {
      // 只埋搜索
      actionSearch(this.searchText);
    }
  },
  startSampleItem(item: ExampleEffectItem) {
    const effct = this.effectList.find(
      (ele) => ele.prodId === item.templateId || ele.id + "" === item.templateId
    );
    if (!effct) {
      return;
    }
    this.currentEffect = effct;
    return this.uploadImage("try_now_upload", null, false, true);
  },
  async startAUrl(url: string, autoRun = false) {
    const blob = await downloadFile(url);
    const lastName = new URL(url).pathname.split("/").pop() || "image.jpg";
    const file = new File([blob], lastName, {
      type: blob.type
    });
    this.uploadImage("from_url", file, false, false, autoRun);
  }
});

export default FTImageStore;
