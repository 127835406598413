import { FotorPictureSelect, FileSourceType } from "@ft/picture-select";
import { useState } from "react";
import { urls } from "src/constant";
import { useLanguage } from "src/hooks";
import styles from "./index.module.scss";
import clsx from "clsx";
import { ArrowIcon } from "src/assets";
import { FTUserStore } from "src/store";
import { utils } from "src/utils";
interface type {
  className: string;
  onChange: (el: File) => void;
}
const SelectImagesComp = (props: type) => {
  const { className, onChange } = props;
  const [open, setOpen] = useState(false);
  const { language } = useLanguage();

  async function onchange(files: Array<any>) {
    if (files.length > 1) {
      return;
    }

    if (files?.length === 1) {
      let item = files[0];
      if (!item.file) {
        // 云相册
        item.file = (await utils.getImageFileFromUrl(
          item.url,
          `${item.name}`
        )) as File;
      }
      if (
        ["image/png", "image/jpeg", "image/webp"].indexOf(item.file.type) === -1
      ) {
        return undefined;
      }

      onChange && onChange(item.file);
      // FTImageStore.uploadImage("drop_upload", item.file);
    }
  }

  return (
    <FotorPictureSelect
      onClickItem={(key) => {
        // onClickItemEvent && onClickItemEvent(key);
        // SelectType = key;
      }}
      zIndex={10001}
      theme='dark'
      isLogin={!!FTUserStore.userInfo}
      maidian={{ project_type: "goart" }}
      // placement={placement}
      onOpenChange={(visible) => {
        setOpen(visible);
      }}
      // dropdownClassName={clsx(
      //   dropdownClassName && dropdownClassName,
      //   style["pulic-drop-list-style"]
      // )}
      //   isLogin={!!FTUserStore.userInfo}
      domain={
        urls.baseUrl!.endsWith("/") ? urls.baseUrl!.slice(0, -1) : urls.baseUrl
      }
      fotorCloudDomain={process.env.REACT_APP_CLOUD_DOMAIN}
      isDropboxChooser={true}
      hiddenFromItems={[FileSourceType.FromGooglePhoto]}
      accept={".png,.jpeg,.jpg,.webp"}
      limitCount={1}
      onSelect={onchange}
      lang={language}
      onLogin={() => {
        let doms: HTMLDivElement | null =
          document.querySelector(".dn-modal-open");
        // if (doms) {
        //   doms.style.display = "none";
        // }
        FTUserStore.showLogin({
          isSignIn: false,
          options: {
            position: "goart_ad_login"
          },
          isShowScenses: true,
          otherParam: {
            scenesId: "015"
          },
          callback: () => {
            if (doms) {
              doms.style.display = "flex";
            }
            FTUserStore.getUserActivity();
          },
          errorCallback: () => {
            if (doms) {
              doms.style.display = "flex";
            }
          }
        });
        // FTUserStore.showLogin({
        //   isSignIn: true,
        //   options: {
        //     position: "smalltool_avatar"
        //   },
        //   isShowScenses: true,
        //   otherParam: {
        //     scenesId: "016"
        //   },
        //   callback: () => {
        //     if (doms) {
        //       doms.style.display = "flex";
        //     }
        //     FTUserStore.getUserActivity();
        //   },
        //   errorCallback: () => {
        //     if (doms) {
        //       doms.style.display = "flex";
        //     }
        //   }
        // });
      }}>
      <div
        className={clsx(
          styles["upload-right"],
          open && styles["upload-right-active"],
          className
        )}>
        <ArrowIcon width={16} />
      </div>
    </FotorPictureSelect>
  );
};
export default SelectImagesComp;
