import React from "react";
import styles from "./FTProgress.module.scss";

interface IProps {
  progress?: number;
  startColor?: string;
  endColor?: string;
}
export default function FTProgress(props: IProps) {
  let { progress = 5, startColor = "#A3FFF6", endColor = "#265DEB" } = props;
  const progressNew = progress > 5 ? progress : 5;
  const style = {
    // background: `linear-gradient(90deg,${startColor} 0%,${endColor} 100%)`,
    width: progressNew + "%"
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.bg}></div>
      <div className={styles.bar} style={style}></div>
    </div>
  );
}
